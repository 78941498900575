import styles from './Footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <h1>Damian</h1>
    </footer>
  );
}

export default Footer;