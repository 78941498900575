import { useState } from 'react';
import { data } from '../../data/data';
import ItemList from '../items/ItemList';
import MoneyCounter from '../moneyCounter/MoneyCounter';
import Title from '../title/Title';
import styles from './Content.module.css';

const Content = () => {
  const [money, setMoney] = useState(data.getMoney());

  const updateMoney = () => {
    setMoney(data.getMoney());
  }
  
  return (
    <main className={styles.main}>
      <Title/>
      <MoneyCounter money={money} />
      <ItemList updateMoney={updateMoney} />
    </main>
  );
}

export default Content;