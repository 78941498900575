import { ItemProps } from "./items";

let money = 1000000000;
let basket: ItemProps[] = [];

const getMoney = () => {
    return money;
}

const buyItem = (price: number) => {
    if (money >= price) {
        money -= price;
        return true;
    }
    return false;
}

const sellItem = (price: number) => {
    money += price;
}

const getAmountOfItemsInBasket = (item: ItemProps) => {
    return basket.filter((i) => i === item).length;
}

const addItemToBasket = (item: ItemProps) => {
    basket.push(item);
}

const removeItemFromBasket = (item: ItemProps) => {
    const index = basket.findIndex((i) => i === item)
    if (index !== -1) {
      basket.splice(index, 1)
    }
  }

export const data = {
    getMoney,
    buyItem,
    sellItem,
    getAmountOfItemsInBasket,
    addItemToBasket,
    removeItemFromBasket
};