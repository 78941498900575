export interface ItemProps {
    id: number;
    name: string;
    price: number;
}

export const items: ItemProps[] = [
    { id: 1, name: 'Big Mac', price: 2 },
    { id: 2, name: 'Flip Flops', price: 3 },
    { id: 3, name: 'Appel', price: 0.5 },
    { id: 4, name: 'Nike Air Max', price: 238.95 },
    { id: 5, name: 'iPhone 15 Pro', price: 1200 },
    { id: 6, name: 'Laptop', price: 1500 },
    { id: 7, name: 'Mountain Bike', price: 800 },
    { id: 8, name: 'PlayStation 5', price: 499 },
    { id: 9, name: 'AirPods', price: 199 },
    { id: 10, name: 'Samsung TV', price: 1000 },
    { id: 11, name: 'Wasmachine', price: 600 },
    { id: 12, name: 'Droogkast', price: 700 },
    { id: 13, name: 'Koelkast', price: 900 },
    { id: 14, name: 'Magnetron', price: 300 },
    { id: 15, name: 'Broodrooster', price: 50 },
    { id: 16, name: 'Stofzuiger', price: 150 },
    { id: 17, name: 'Espressomachine', price: 200 },
    { id: 18, name: 'Koffiezetapparaat', price: 100 },
    { id: 19, name: 'Waterkoker', price: 40 },
    { id: 20, name: 'Strijkijzer', price: 60 },
    { id: 21, name: 'Mixer', price: 80 },
    { id: 22, name: 'Blender', price: 120 },
    { id: 23, name: 'Airfryer', price: 130 },
    { id: 24, name: 'Keukenrobot', price: 300 },
    { id: 25, name: 'Elektrische step', price: 400 },
    { id: 26, name: 'Elektrische fiets', price: 2000 },
    { id: 27, name: 'Scooter', price: 2500 },
    { id: 28, name: 'Auto', price: 25000 },
    { id: 29, name: 'Motorfiets', price: 15000 },
    { id: 30, name: 'Boot', price: 50000 },
    { id: 31, name: 'Jacht', price: 200000 },
    { id: 32, name: 'Helikopter', price: 500000 },
    { id: 33, name: 'Vliegtuig', price: 1000000 },
    { id: 34, name: 'Spaceshuttle', price: 50000000 },
    { id: 35, name: 'Rolex', price: 8000 },
    { id: 36, name: 'Zonnebril', price: 150 },
    { id: 37, name: 'Handtas', price: 200 },
    { id: 38, name: 'Portemonnee', price: 100 },
    { id: 39, name: 'Riem', price: 50 },
    { id: 40, name: 'Schoenen', price: 120 },
    { id: 41, name: 'Jas', price: 300 },
    { id: 42, name: 'Kostuum', price: 500 },
    { id: 43, name: 'Jurk', price: 150 },
    { id: 44, name: 'T-shirt', price: 30 },
    { id: 45, name: 'Broek', price: 80 },
    { id: 46, name: 'Pet', price: 25 },
    { id: 47, name: 'Sjaal', price: 40 },
    { id: 48, name: 'Handschoenen', price: 35 },
    { id: 49, name: 'Paraplu', price: 20 },
    { id: 50, name: 'Laptop Tas', price: 60 },
    { id: 51, name: 'Tablet', price: 400 },
    { id: 52, name: 'Smartwatch', price: 250 },
];