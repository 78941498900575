import { FC } from 'react';
import { data } from '../../data/data';
import { ItemProps } from '../../data/items';
import styles from './Items.module.css';

interface ItemWrapperProps {
    item: ItemProps
    updateMoney: () => void;
}

export const ItemWrapper: FC<ItemWrapperProps> = ({ item, updateMoney }) => {
    const { id, name, price } = item;

    const amountOfItemsInBasket = data.getAmountOfItemsInBasket(item);

    const buyItem = () => {
        const result = data.buyItem(price);
        if (result) {
            updateMoney();
            data.addItemToBasket(item);
        } else {
            alert('Not enough money!');
        }
    }

    const sellItem = () => {
        data.sellItem(price);
        data.removeItemFromBasket(item);
        updateMoney();
    }

    return (
        <div className={styles.itemWrapper} key={id}>
            <p className={styles.name}>{ name }</p>
            <p className={styles.price}>${ price }</p>
            <div className={styles.buttons}>
                <button
                    className={`${styles.button} ${styles.sell}`}
                    onClick={sellItem}
                    disabled={amountOfItemsInBasket === 0}
                >
                    Sell
                </button>
                <p className={styles.amount}>{ amountOfItemsInBasket }</p>
                <button
                    className={styles.button}
                    onClick={buyItem}
                >
                    Buy
                </button>
            </div>
        </div>
)};