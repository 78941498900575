import styles from './Title.module.css';

const Title = () => {
  return (
    	<div className={styles.wrapper} >
        <h1 className={styles.title} >Spend Billionairs money 🤑</h1>
      </div>
    );
}

export default Title; 