import { FC } from 'react';
import { NumberToDollar } from '../../utils/numberHelper';
import styles from './MoneyCounter.module.css';

interface MoneyCounterProps {
    money: number;
}

const MoneyCounter: FC<MoneyCounterProps> = ({ money }) => {
    return <div className={styles.counter}>{ NumberToDollar(money) }</div>;
}

export default MoneyCounter;
