import { FC } from 'react';
import { ItemProps, items } from '../../data/items';
import { ItemWrapper } from "./ItemWrapper";
import styles from './Items.module.css';

interface ItemsProps {
    updateMoney: () => void;
}

const ItemList: FC<ItemsProps> = ({ updateMoney }) => {
    const itemsOrderedByAmount = items.sort((a: ItemProps, b: ItemProps) => a.price - b.price);
    return (
        <div className={styles.items}>
            { itemsOrderedByAmount.map((item) => <ItemWrapper item={item} updateMoney={updateMoney} />) }
        </div>
    );
}

export default ItemList;